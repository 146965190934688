
import { Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import AboutUs from "./pages/AboutUs";
import Farmer from "./pages/farmer/Farmer";
import Buyer from "./pages/buyer/Buyer";
import FarmerRegistration from "./component/utility/farmer/FarmerRegistration";
import ProductMapFarmer from "./pages/farmer/ProductMap";
import ProductMapBuyer from "./pages/buyer/ProductMap";
import Profile from "./pages/farmer/Profile";
import ProfileBuyer from "./pages/buyer/Profile";
import Transaction from "./pages/buyer/Transaction";
import Contact from "./pages/Contact";

import FpoDashboard from "./pages/farmer/FpoDashboard";
import FarmerList from "./pages/farmer/FarmerList";
import Mapping from "./pages/farmer/Mapping";

import WIP from './pages/WIP';

import './style/main.scss';
import FarmerAdd from "./pages/farmer/FarmerAdd";
import AddDailyProduction from "./pages/farmer/AddDailyProduction";
import ProductFarmerList from "./pages/farmer/ProductFarmerList";

function App() {
  return (
    <div className="App" >
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/about-us" element={ <AboutUs /> } />
        <Route path="/farmer" element={ <Farmer /> } />
        <Route path="/fpo-dashboard" element={ <FpoDashboard /> } />
        <Route path="/farmer-list" element={ <FarmerList /> } />
        <Route path="/mapping" element={ <Mapping /> } />
        <Route path="/product-farmer-list" element={ <ProductFarmerList /> } />
        <Route path="/add-farmer" element={ <FarmerAdd /> } />
        <Route path="/add-daily-production" element={ <AddDailyProduction /> } />
        <Route path="/buyer" element={ <Buyer /> } />
        <Route path="/registration" element={ <FarmerRegistration /> } />
        <Route path="/productMapFarmer" element={ <ProductMapFarmer /> } />
        <Route path="/productMapBuyer" element={ <ProductMapBuyer /> } />
        <Route path="/profile" element={ <Profile /> } />
        <Route path="/profile-buyer" element={ <ProfileBuyer /> } />
        <Route path="/transaction" element={ <Transaction /> } />
        <Route path="/contact" element={ <Contact /> } />
        
        <Route path="*" element={ <WIP /> } />
      </Routes>
    </div>
  );
}

export default App;