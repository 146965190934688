import React, { useState } from 'react';

import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Badge,
    Modal,
    ModalBody,
    ModalHeader,

} from 'reactstrap';
import FarmerProfile from './FarmerProfile';

const FarmerCard = ({data, ...props}) => {
    const [profileModal, setProfileModal] = useState(false);

    return(
        <>
            <Col md={3} className='mb-2'>
                <Card onClick={() => setProfileModal(true)}>
                    <CardBody>
                        <CardTitle tag="h5">{data.name}</CardTitle>
                        <div className="d-flex justify-content-between">
                            <div><b>Registration No.:</b></div>
                            <div>{data?.farmer_reg}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div><b>Animal Count:</b></div>
                            <div>{data?.animal_count}</div>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div><b>Daily Milk Production:</b></div>
                            <div>{data?.productList[0]?.max_qty} Ltrs</div>
                        </div>
                        <div>
                            { (data?.productList?.length > 0) ? 
                                data.productList.map((row, index) => {
                                    return(
                                        <Badge className="me-1" color="primary" pill key={index} >{row.product_name}</Badge>
                                    )
                                })
                                :
                                <Badge className="me-1" color="primary" pill >No Product Added</Badge>
                            }
                            
                        </div>
                    </CardBody>
                </Card>
            </Col>

            <Modal
                centered
                scrollable
                size="md"
                isOpen={profileModal}
                toggle={() => setProfileModal(!profileModal)}
            >
                <ModalHeader toggle={() => setProfileModal(!profileModal)}>
                    Farmer Profile
                </ModalHeader>
                <ModalBody className='bg-light'>
                    <FarmerProfile data={data} />
                </ModalBody>
            </Modal>
        </>
    )
}

export default FarmerCard;