import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Button, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import HeaderFarmer from '../../component/layout/HeaderFarmer';
import FooterSmall from '../../component/layout/FooterSmall';
import Page from '../../component/layout/Page';
import { notification } from '../../component/hocs/notification';
import axios from 'axios';
import { mainUrl } from '../../MainUrl';
import { FaPlusCircle } from 'react-icons/fa';
import Select from 'react-select';

const nolist =  <Col md={3} className='mb-2'>
<Card>
    <CardBody>
        <CardTitle tag="p">No Mapping List Found!</CardTitle>
    </CardBody>
</Card>
</Col>

const Mapping = () => {
    const [mode, setMode] = useState(1);
    const [loading, setLoading] = useState(false);

    const [mappingList, setMappingList] = useState([]);
    const [mapModal, setMapModal] = useState(false);
    const [buyer, setBuyer] = useState('');
    const [buyerOpt, setBuyerOpt] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);
        getmappinglist();
        getBuyerList();
    }, []);

    const getBuyerList = () => {
        
        axios({
            method: 'post',
            url: mainUrl + 'mis/getBuyerList',
            headers: { 'Content-Type': 'application/JSON', }
        })
        .then(function (response) {
            if(response.data.status === 1 && response.data.data.length > 0){
                let list = response.data.data.map(row => {
                    return { value: row.buyer_code, label: row.org_name +' ('+ row.buyer_code +')' }
                });
                setBuyerOpt(list);

            }

        }).catch(error => {
            console.log(error)
            setLoading(false);
            let notify = notification({ message: "Failed! Try Again", type: 'error' });
            notify();
        })
    }

    const getmappinglist = () => {
        let postData = {"fpo_code": sessionStorage.getItem('user_id')}
        axios({
            method: 'post',
            url: mainUrl + 'mis/getFpoBuyerMappingList',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
            .then(function (response) {
                if(response.data.status === 1 && response.data.data.length > 0){
                    let list = response.data.data.map((row, index) => {
                        return (
                            <Col md={3} className='mb-2'>
                                <Card>
                                    <CardBody>
                                        <div className="d-flex justify-content-between">
                                            <div><b>Buyer Code:</b></div>
                                            <div>{row.buyer_code}</div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div><b>Buyer Name:</b></div>
                                            <div>{row.imilk_buyer.org_name}</div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <div><b>Phone:</b></div>
                                            <div>{row.imilk_buyer.mobile_no}</div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    });
                    setMappingList(list)
                   
                }else{
                    setMappingList(nolist);
                    setLoading(false);
                }

            }).catch(error => {
                console.log(error)
                setLoading(false);
                let notify = notification({ message: "Failed! Try Again", type: 'error' });
                notify();
            })
    }

    const add_map_handler = () => {

        let postData = {"fpo_code": sessionStorage.getItem('user_id'), "buyer_code": buyer.value}
        axios({
            method: 'post',
            url: mainUrl + 'mis/fpoBuyerMapping',
            data: postData,
            headers: { 'Content-Type': 'application/JSON', }
        })
        .then(function (response) {
            if(response.data.status === 1 ){
                let notify = notification({ message: response.data.message, type: 'success' });
                notify();
                getmappinglist();
                setMapModal(false);
            }else{
                setMapModal(false);
                let notify = notification({ message: response.data.message, type: 'warn' });
                notify();
            }
            setLoading(false);

        }).catch(error => {
            console.log(error)
            setLoading(false);
            let notify = notification({ message: "Failed! Try Again", type: 'error' });
            notify();
        })
    }

    return (
        <div>
            <HeaderFarmer />
            <Page>
                <Row className='p-3'>
                    <Col xs="12">
                        <Card className='mb-2'>
                            <CardBody className='d-flex justify-content-between'>
                                <h5>Buyer Mapping List</h5>
                                <Button className='ms-3' color='primary' size="sm" onClick={() => setMapModal(true)} ><FaPlusCircle size={20} /> Map New Buyer </Button>
                            </CardBody>
                        </Card>
                    </Col>

                    {mappingList}
                </Row>
            </Page>
            <FooterSmall />

            <Modal
                centered
                scrollable
                size="md"
                isOpen={mapModal}
                toggle={() => setMapModal(!mapModal)}
            >
                <ModalHeader toggle={() => setMapModal(!mapModal)}>
                    Map New Buyer
                </ModalHeader>
                <ModalBody className='bg-light'>
                    <Row>
                    <Col xs={12} className="mb-4">
                        <Label for="buyer"> Buyer </Label>
                        <Select
                            styles={{ option: (base) => ({ ...base, fontSize: '0.8em', lineHeight: '1' }) }}
                            value={buyer}
                            onChange={(opt => setBuyer(opt))}
                            options={buyerOpt}
                            maxMenuHeight={140}
                            placeholder="Select Buyer to Map"
                        />
                    </Col>
                    <Col xs={12} className="mb-5 text-end">
                        <Button className='' color='primary' size="sm" onClick={add_map_handler} >
                            <FaPlusCircle size={20} /> Map
                        </Button>
                    </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </div>


    )
}

export default Mapping;